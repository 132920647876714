<template>
  <form>
    <div class="form-group">
      <label for="select_cat">Select Category</label>
      <select
        class="form-control"
        id="select_cat"
        v-model="form.listing_category_id"
      >
        <option
          v-for="listingCategory in listingCategories"
          :key="listingCategory.id"
          :value="listingCategory.id"
        >
          {{ listingCategory.name }}
        </option>
      </select>
      <small v-if="errors && errors.listing_category_id" class="text-danger">
        {{ errors.listing_category_id[0] }}
      </small>
    </div>
    <div class="form-group">
      <label for="catDescription">Title</label>
      <input class="form-control" id="catTitle" v-model="form.title" />
      <small v-if="errors && errors.title" class="text-danger">
        {{ errors.title[0] }}
      </small>
    </div>
    <div class="form-group">
      <label for="catDescription">Description</label>
      <textarea
        class="form-control"
        id="catDescription"
        rows="3"
        v-model="form.description"
      ></textarea>
      <small v-if="errors && errors.description" class="text-danger">
        {{ errors.description[0] }}
      </small>
    </div>
    <div class="form-group">
      <label for="catLocation">Location</label>
      <input
        class="form-control"
        id="catLocation"
        placeholder="Enter town/city in zimbabwe"
        type="text"
        v-model="listingLocation"
      />
      <small v-if="errors && errors.listingLocation" class="text-danger">
        {{ errors.listingLocation[0] }}
      </small>
    </div>
    <div class="form-group">
      <label for="listingPhone">Phone Number</label>
      <input
        class="form-control"
        id="listingPhone"
        placeholder="Enter phone number"
        type="phone"
        v-model="form.phone_number"
      />
      <small v-if="errors && errors.phone_number" class="text-danger">
        {{ errors.phone_number[0] }}
      </small>
    </div>
    <div class="form-group">
      <label for="catEmail">Email</label>
      <input
        class="form-control"
        id="catEmail"
        placeholder="Enter email"
        type="text"
        v-model="form.email"
      />
      <small v-if="errors && errors.email" class="text-danger">
        {{ errors.email[0] }}
      </small>
    </div>
    <div class="form-group">
      <label for="catPrice">Price</label>
      <input
        class="form-control"
        id="catPrice"
        placeholder="Enter Price"
        type="number"
        v-model="form.price"
      />
      <small v-if="errors && errors.price" class="text-danger">
        {{ errors.price[0] }}
      </small>
    </div>
    <div class="form-group">
      <label for="listingImage">Image (No Videos)</label>
      <input
        id="listingImage"
        class="form-control-file border"
        type="file"
        @change="handleFileUpload($event)"
      />
    </div>

    <div class="justify-content-between mb-4 mt-4">
      <div>
        <button
          v-if="auth.isAuthenticated"
          :disabled="isLoading"
          type="submit"
          class="ps-btn btn-sm"
          @click.prevent="onSubmit()"
        >
          <i v-if="!isLoading" class="icon-floppy-disk ml-1"></i>
          <i v-if="isLoading" class="icon-refresh ml-1"></i>
          {{ isLoading ? "Processing..." : "Submit" }}
          <div
            v-if="isLoading"
            class="spinner-border text-light"
            role="status"
          ></div>
        </button>
      </div>
    </div>

    <!--        <router-link v-else :to="{ name: 'login' }">-->
    <!--          <button type="submit" class="btn btn-primary ls-submit-button">-->
    <!--            Submit-->
    <!--          </button>-->
    <!--        </router-link>-->
  </form>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "ListingForm",
  props: {
    listingId: Number,
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  data() {
    return {
      listingLocation: "Harare, Zimbabwe",
      listingCategories: [],
      form: {
        title: "",
        description: "",
        listing_category_id: "1",
        listing_type: "sale",
        latitude: "-17.8331136",
        longitude: "31.0242752",
        price: "",
        phone_number: "",
        email: "",
        uploadImageFile: "",
      },
      formData: {},
      isLoading: false,
      errors: null,
      message: null,
      isError: false,
    };
  },
  mounted() {
    this.formData = new FormData();

    if (this.listingId) {
      this.getListing();
    }

    this.getCategories();
  },
  methods: {
    getListing() {
      if (this.listingId) {
        axios.get(`listings/${this.listingId}`).then((response) => {
          this.listing = response.data;
          this.form.title = this.listing.title;
          this.form.description = this.listing.description;
          this.form.listing_category_id = this.listing.listing_category_id;
          this.form.listing_type = this.listing.listing_type;
          this.form.latitude = this.listing.latitude;
          this.form.longitude = this.listing.longitude;
          this.form.price = this.listing.price;
          this.form.phone_number = this.listing.phone_number;
          this.form.email = this.listing.email;

          this.formData.append("file", this.form.uploadImageFile);
          this.formData.append("title", this.listing.title);
          this.formData.append("description", this.listing.description);
          this.formData.append(
            "listing_category_id",
            this.listing.listing_category_id
          );
          this.formData.append("listing_type", this.listing.listing_type);
          this.formData.append("latitude", this.listing.latitude);
          this.formData.append("longitude", this.listing.longitude);
          this.formData.append("price", this.listing.price);
          this.formData.append("phone_number", this.listing.phone_number);
          this.formData.append("email", this.listing.email);
        });
      }
    },

    async onSubmit() {
      //Prepare data before saving
      this.formData.append("file", this.form.uploadImageFile);
      this.formData.append("title", this.form.title);
      this.formData.append("description", this.form.description);
      this.formData.append(
        "listing_category_id",
        this.form.listing_category_id
      );
      this.formData.append("listing_type", this.form.listing_type);
      this.formData.append("latitude", this.form.latitude);
      this.formData.append("longitude", this.form.longitude);
      this.formData.append("price", this.form.price);
      this.formData.append("phone_number", this.form.phone_number);
      this.formData.append("email", this.form.email);

      if (this.listingId) {
        await this.updateListing();
      } else {
        await this.createListing();
      }
    },

    createListing() {
      this.isLoading = true;
      axios
        .post("listings", this.formData)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.handleSuccess("Listing created successfully.");
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.handleErrors(error, "Failed to Create Listing.");
        });
    },

    updateListing() {
      this.isLoading = true;
      axios
        .patch(`listings/${this.listingId}`, this.formData)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.handleSuccess("Listing updated successfully.");
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.handleErrors(error, "Failed to Create Listing.");
        });
    },

    handleFileUpload($event) {
      this.form.uploadImageFile = event.target.files[0];
      console.log("here", event.target.files[0]);
    },

    uploadImage(listing_id) {
      let formData = new FormData();
      formData.append("file", this.listing.uploadImageFile);
      formData.append("listing_id", listing_id);
      formData.append("is_featured", "true");

      axios
        .post("listings/images", formData)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          if (response.data.success === "true") {
            this.$toasted.show("Image was uploaded successfully");
          } else {
            this.$toasted.error("Failed to upload : " + response.data.message);
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;

          this.$toasted.error(
            "Failed to upload. An error occurred, please try again"
          );
        });
    },

    getCategories() {
      this.form.email = this.auth.user.email;
      this.form.phone_number = this.auth.user.phone_number;
      axios.get("listings/categories").then((response) => {
        this.listingCategories = response.data;
        this.form.listing_category_id = response.data[0].id;
      });
    },

    handleSuccess(message) {
      this.isLoading = false;
      this.$toasted.success(message);

      this.form = {
        title: "",
        description: "",
        listing_category_id: "",
        listing_type: "",
        latitude: "",
        longitude: "",
      };
      this.$router.push({ name: "my_listings" });

      setTimeout(() => {
        this.$router.push({ name: "my_listings" });
      }, 1000);
    },

    handleErrors(error, message) {
      if (error.response.status === 422) {
        if (message) {
          this.$toasted.error(message);
        }
        this.errors = error.response.data.errors;
      } else {
        this.message = error.backendErrors[0];
      }
      this.isError = true;
      this.isLoading = false;
    },
  },
};
</script>

<style scoped></style>