<template>
  <div class="row">
    <div class="col-xl-2 col-lg-3 col-md-2 col-12"></div>
    <div class="col-xl-8 col-lg-6 col-md-8 col-12">
      <h3>Edit Listing</h3>
    </div>
    <ListingForm :listing-id="$route.params.id" ref="listingForm"></ListingForm>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "axios";
import ListingForm from "./ListingForm";
export default {
  name: "CreateListing",
  components: {ListingForm},
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  data() {
    return {
      listingLocation: "Harare, Zimbabwe",
      listingCategories: [],
      listing: {
        title: "",
        description: "",
        listing_category_id: "1",
        listing_type: "sale",
        latitude: "-17.8331136",
        longitude: "31.0242752",
      },
      isLoading: false,
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    createListing() {
      this.isLoading = true;
      let data = {
        title: this.listing.title,
        description: this.listing.description,
        listing_category_id: this.listing.listing_category_id,
        listing_type: this.listing.listing_type,
        latitude: this.listing.latitude,
        longitude: this.listing.longitude,
      };

      axios
        .post("listings", data)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            console.log("Listing Created: " + JSON.stringify(response.data));
            this.$toasted.success("Listing Has been created.");

            this.listing = {
              title: "",
              description: "",
              listing_category_id: "",
              listing_type: "",
              latitude: "",
              longitude: "",
            };
          }

          console.log("Listing Failed: " + JSON.stringify(response.data));
          this.isLoading = false;
        })
        .catch((error) => {
          this.$toasted.error("Failed to Create Listing..");
          this.isLoading = false;
        });
    },

    getCategories() {
      axios.get("listings/categories").then((response) => {
        console.log("Get Listing Categories: " + JSON.stringify(response.data));
        this.listingCategories = response.data;
        this.listing.listing_category_id = response.data[0].id;
      });
    },
  },
};
</script>
